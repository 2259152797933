import React, { useState, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import "../template/portfoliofrancky/scss/app.scss";
import {
  FaPhone,
  FaPlay,
  FaUser,
  FaLinkedin,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaCheck,
} from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck } from "react-icons/hi";
import "../../fonts/Lato/Lato-Bold.ttf";
import "../../fonts/Poppins/Poppins-Bold.ttf";
import "../../fonts/Poppins/Poppins-Regular.ttf";
import "../../fonts/OpenSans-Regular.ttf";
import Home from "./home/Home";
import About from "./about/About";
import Contact from "./contact/Contact";
import Blog from "./blog/Blog";
import ReadBlog from "./blog/ReadBlog";
import Login from "./login/Login";
import Subscribe from "./login/Subscribe";
import Search from "./search/Search";
import SearchPeople from "./search/SearchPeople";
import ProfileHome from "./profile/ProfileHome";
import ProfileUpdate from "./profile/ProfileUpdate";
import AppContext, { AppContextProvider } from "./context/AppContext";

import Header from "./header/Header";
import HeaderLogin from "./header/HeaderLogin";
import ViewProfile from "./profile/ViewProfile";
import JokePage from "./jokes/JokePage";
import DedicatedMessages from "./dedicaces/DedicatedMessages";
import Impressum from "./portfolio/Impressum";
import * as tools from "./utils/tools";
import UserMap from "./profile/usermap/UserMap";
import ProfileTopMenu from "./profile/ProfileTopMenu";
import { UserProvider } from "./context/UserContext";
import UserContext from "./context/UserContext";
import UserClassrooms from "./profile/UserClassrooms";
import ViewClassroom from "./profile/ViewClassroom";
import ViewClassroomSettings from "./profile/ViewClassroomSettings";
import AddNote from "./profile/AddNote";
import ProfileFiles from "./profile/ProfileFiles";
import UserNotifications from "./profile/UserNotifications";
import AdminLogin from "./admindashboard/login/AdminLogin";
import Dashboard from "./admindashboard/dashboard/Dashboard";
import UsersList from "./admindashboard/users/UsersList";
import AssignGasList from "./admindashboard/assign-gas/AssignGasList";
import EmployeeProfile from "./gasmanagementapp/EmployeeProfile";
import InputFuelConsumption from "./gasmanagementapp/InputFuelConsumption";
import ArticleList from "./admindashboard/new-article/ArticleList";
import DashboardFileUpload from "./admindashboard/fileupload/DashboardFileUpload";
import UploadPage from "./admindashboard/fileupload/UploadPage";
import TemplatesPage from "./admindashboard/templates/TemplatesPage";
import AppSettings from "./admindashboard/app-settings/AppSettings";
import MessagesPage from "./admindashboard/messages/MessagesPage";
import ClassroomsList from "./admindashboard/classrooms-management/ClassroomsList";
import ReadClassroomsList from "./admindashboard/classrooms-management/readclassroom/ReadClassroomsList";
import BarLoader from "react-spinners/BarLoader";
import { useBarLoader } from "../../hooks/useBarLoader/useBarLoader";
import FinishCmsSetup from "./admindashboard/login/FinishCmsSetup";

let baseURL: any;
baseURL = process.env.REACT_APP_API_URL;

const AppContainer = () => {
  const [state, setState] = useState({
    userLogin: false,
    showLeftBar: false,
    messageSent: false,

    recentPosts: [],
  });

  const [contactState, setContactState] = useState({
    userName: "",
    userEmail: "",
    userMessage: "",
  });

  const [loginState, setLoginState] = useState({
    login: false,
  });

  const [userState, setUserState] = useState({
    userEmail: "",
    userName: "",
  });

  const {
    isLogin,
    setIsLogin,
    checkToken,
    setCheckToken,
    checkingToken,
    errorLogin,
    userInfo,
    setUserInfo,
    setAdminAuthInfo,
    adminAuthInfo,
  } = useContext(AppContext);

  const iconStyle = { fill: "#4ad", width: "32px", height: "26px" };

  const toggleLeftBar = () => {
    setState({
      ...state,
      showLeftBar: !state.showLeftBar,
    });
  };

  const submitContactForm = (e: any) => {
    e.preventDefault();
    let dataToSend = {
      message: contactState.userMessage,
      email: contactState.userEmail,
      name: contactState.userName,
    };

    axios.post(baseURL + "contactus", dataToSend).then((response) => {
      console.log(response.data.status);
      console.log(typeof response.data.status);
      if (response.data.status === "success") {
        console.log("message sent");
        setState({
          ...state,
          messageSent: true,
        });
      } else {
        setState({
          ...state,
          messageSent: false,
        });
      }
    });
  };

  const checkLoginState = (isLoggedIn: boolean) => {
    //appContext=useContext(AppContext);
    setLoginState({
      ...loginState,
      login: isLoggedIn,
    });
  };
  const handleFormChange = (e: any) => {
    setContactState({
      ...contactState,
      [e.target.name]: e.target.value,
    });
  };

  const checkUserOnline = () => {
    //appContext=useContext(AppContext);
    //console.log(appContext.isOnline);
    //checkingToken();
    if (localStorage.getItem("loginToken") !== null && checkToken) {
      checkLoginState(true);
      setIsLogin(true);

      setUserInfo((prevState: any) => ({
        ...prevState,
        name: localStorage.getItem("userName"),
        biography: localStorage.getItem("userBiography"),
        money: parseInt(localStorage.getItem("userMoney") + "", 10),
        profilePicture: localStorage.getItem("profilePicture"),
        id: localStorage.getItem("userId"),
      }));
      return true;
    }
    return false;
  };

  const getUserData = () => {
    axios
      .post(baseURL + "getuserdata/", {
        header: localStorage.getItem("loginToken"),
      })
      .then((response: any) => {
        //       console.log('user name', response.data.userName);
        setUserState({
          ...userState,
          userName: response.data.user.userName,
        });
      });
  };

  //change profile state
  const [changeProfile, setChangeProfile] = useState(false);

  const [makeHeaderFixed, setMakeHeaderFixed] = useState(false);

  const [showSubmenu, setShowSubmenu] = useState(true);

  const hideSubmenu = (pshow: boolean) => {
    setShowSubmenu(pshow);
  };
  const scrollEventHeader = () => {
    window.addEventListener("scroll", function (e: any) {
       
      let userScroll = document.documentElement.scrollTop;

      if (userScroll >= 25) {
        setMakeHeaderFixed(true);
      } else if (userScroll <= 0) {
        setMakeHeaderFixed(false);
      }
    });
  };

  const simpleUserAuthentication =
    checkToken && isLogin ? (
      <>
        <HeaderLogin
          fixedProp={makeHeaderFixed}
          loginProp={checkLoginState}
          profileProp={setChangeProfile}
        />
        {/*<ProfileTopMenu/>*/}
      </>
    ) : (
      <>
        {/*<Header fixedProp={makeHeaderFixed} submenuProp={showSubmenu}
             hideSubmenuProp={hideSubmenu} />*/}
      </>
    );

  //admin route
  const checkAdminRoute = adminAuthInfo.loggedIn ? (
    <>
      <p>Welcome admin, logout</p>
    </>
  ) : (
    <>checkDefaultRoute()</>
  );

  //user default route
  const checkDefaultRoute =
    checkToken && isLogin ? (
      <>
        {/* gonna put all the features here, people have to connect */}
        {/*<Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />

        <Route path="/search" element={<Search />} />
        <Route path="/search/:slug/" element={<Search />} />
        <Route path="/search/people/:slug" element={<SearchPeople />} />
        <Route path="/search/article/:slug" element={<Search />} />
        <Route path="/search/classrooms/:slug" element={<Search />} />
        <Route path="/search/portfolio/:slug" element={<Search />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<ReadBlog />} /> */}

        <Route
          path="/profile"
          element={
            <ProfileHome
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />
        <Route
          path="/profile/update"
          element={
            <ProfileUpdate
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />
        <Route
          path="/profile/classrooms"
          element={
            <UserClassrooms
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />
        <Route
          path="/profile/files"
          element={
            <ProfileFiles
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />

        <Route
          path="/profile/notes"
          element={
            <AddNote
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />
        <Route
          path="/profile/manageclassroom/:id/home"
          element={<ViewClassroom />}
        />
        <Route
          path="/profile/manageclassroom/:id/settings"
          element={<ViewClassroomSettings />}
        />

        <Route
          path="/profile/alerts"
          element={
            <UserNotifications
              isLoggedInProp={loginState.login}
              loginProp={checkLoginState}
              profileProp={setChangeProfile}
            />
          }
        />
        {/*<Route path="/profile/map" element={<UserMap isLoggedInProp={loginState.login} loginProp={checkLoginState} profileProp={setChangeProfile} />} />*/}
        {/* view user profile */}
        <Route path="/viewprofile/:id" element={<ViewProfile />} />
        {/*smiling features*/}
        {/* <Route path="/smiling/" element={<JokePage />} />

        <Route path="/dedicaces/" element={<DedicatedMessages />} />
        <Route path="/portfolio/" element={<Impressum />} /> */}
        <Route path="*" element={<ProfileHome />} />
      </>
    ) : (
      <>
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/admindashboard" element={<AdminLogin />} />
        <Route path="*" element={<Login loginProp={checkLoginState} />} />
      </>
    );

  const checkAdminToken = () => {
    if (localStorage.getItem("authToken")) {
      setAdminAuthInfo((prevState: any) => ({
        ...prevState,
        loggedIn: true,
      }));
    }
  };

  const { showBarValue, hideBarValue, barValue, setBarValue } =
    useContext(AppContext);

  React.useEffect(() => {
    checkAdminToken();
    checkUserOnline();
    checkingToken();
    //tools.getLastProfile();
    scrollEventHeader();
  }, [isLogin, checkToken, adminAuthInfo.loggedIn]);

  const { showBarLoader, setShowBarLoader, showIt, hideIt } = useBarLoader();

  const [finishSetup, setFinishSetup] = useState(false);
  const checkCmsSetup = () => {
    axios
      .post(tools.getHost() + "adminapi/finishCmsSetup", {})
      .then(({ data }: any) => {
        if (data.status === "success") {
          setFinishSetup(true);
        }
      });
  };

  React.useEffect(() => {
    checkCmsSetup();
  }, []);

  React.useEffect(() => {
    showIt();
  }, []);

  return (
    <>
      <BarLoader width="100%" color="rgb(152, 108, 255)" loading={barValue} />

      <Routes>
        {/* check admin route */}
        {adminAuthInfo.loggedIn && (
          <>
            <Route path="/articles" element={<ArticleList />} />
            <Route path="/users" element={<UsersList />} />
            <Route path="/files" element={<UploadPage />} />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/settings" element={<AppSettings />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/classrooms" element={<ClassroomsList />} />
            <Route path="/classrooms/:slug" element={<ReadClassroomsList />} />
            <Route path="*" element={<Dashboard />} />
          </>
        )}

        {!adminAuthInfo.loggedIn && !finishSetup && (
          <>
            <Route path="*" element={<FinishCmsSetup />} />
          </>
        )}
        {!adminAuthInfo.loggedIn && (
          <>
            <Route path="*" element={<AdminLogin />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppContainer;
