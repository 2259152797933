import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import ClassroomsDataTable from "./ClassroomsDataTable";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  Alert,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Person } from "@mui/icons-material";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import * as Tools from "../../utils/tools";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const baseURL: string = process.env.REACT_APP_API_URL + "";
const appHost: string = process.env.REACT_APP_APP_HOST + "";

const ClassroomsList = () => {
  // create new user modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setUserCreated(false);
    setShowErrorForm(false);
  };
  const handleClose = () => {
    setOpen(false);
    setUserCreated(false);
    setShowErrorForm(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid transparent",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };
  //delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
    setUserDeleted(false);
    setShowErrorFormDelete(false);
    setClickSubmitDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setUserDeleted(false);
    setShowErrorFormDelete(false);
    // setClickSubmitUpdate(false);
  };
  //udpate modal
  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleOpenUpdate = () => {
    setOpenUpdate(true);
    setUserUpdated(false);
    setShowErrorFormUpdate(false);
    setClickSubmitUpdate(false);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setUserUpdated(false);
    setShowErrorFormUpdate(false);
    // setClickSubmitUpdate(false);
  };

  //handling user creation
  const [userCreated, setUserCreated] = useState(false);
  const [showErrorForm, setShowErrorForm] = useState(false);
  //creation
  const [creationState, setCreationState] = useState({
    classroomName: "",
    classroomDescription: "",
    classroomCategory: "",
    userId: 0,
  });

  const handleCreation = (e: any) => {
    setCreationState({
      ...creationState,
      [e.target.name]: e.target.value,
    });
  };
  //udpate

  const [showErrorFormUpdate, setShowErrorFormUpdate] = useState(false);
  const [classroomUpdateState, setclassroomUpdateState]: [any, any] = useState({
    classroomName: "",
    classroomDescription: "",
    classroomCategory: "",
    classroomPublished: 0,
    userId: 0,
    id: 0,
  });

  const handleUpdate = (e: any) => {
    setclassroomUpdateState({
      ...classroomUpdateState,
      [e.target.name]: e.target.value,
    });
  };

  //creation
  const [clickSubmit, setClickSubmit] = useState(false);
  const submitCreate = (e: any) => {
    e.preventDefault();
    setClickSubmit(true);

    Tools.getLightAxios()
      .post(appHost + "adminapi/newclassroom", {
        classroomName: creationState.classroomName,
        classroomDescription: creationState.classroomDescription,
        classroomCategory: creationState.classroomCategory,
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          setUserCreated(true);
          setShowErrorForm(false);
          //added new user, should update list
          e.target.reset();
          setClickSubmit(false);
        } else if (res.data.status.toLowerCase() === "error") {
          setUserCreated(false);
          setShowErrorForm(true);

          setClickSubmit(false);
        }
      });
  };
  //update
  const [clickSubmitUpdate, setClickSubmitUpdate] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);

  const submitUpdate = (e: any) => {
    e.preventDefault();

    setClickSubmitUpdate(true);

    axios
      .post(appHost + "adminapi/updateclassroombyid", classroomUpdateState)
      .then((res: any) => {
        console.log(res.data);
        if (res.data.status.toLowerCase() === "success") {
          setUserUpdated(true);
          setShowErrorFormUpdate(false);
          //added new user, should update list
          e.target.reset();
          setClickSubmitUpdate(false);
        } else if (res.data.status.toLowerCase() === "error") {
          setUserUpdated(false);
          setShowErrorFormUpdate(true);
          setClickSubmitUpdate(false);
        }
      });
  };
  const toggleUpdateModal = (valShowModal: any, articleId: any) => {
    //setOpenUpdate(!valShowModal);
    handleOpenUpdate();

    axios
      .post(Tools.getHost() + "adminapi/getclassroombyid", {
        id: articleId,
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          // console.log('article found ', res.data);

          setclassroomUpdateState({
            ...classroomUpdateState,
            classroomName: res.data.classroom.name,
            id: res.data.classroom.id,
            classroomDescription: res.data.classroom.description,
            classroomPublished: res.data.classroom.published,
            classroomCategory: res.data.classroom.category,
          });
          // console.log('rocket here');
        } else {
          // console.log('some error occured');
        }
      });
  };
  const toggleDeleteModal = (valShowModal: any, articleId: any) => {
    //setOpenUpdate(!valShowModal);
    handleOpenDelete();
    //console.log('deleting ' + articleId);
    axios
      .post(Tools.getHost() + "/adminapi/getuserbyid", {
        userId: articleId,
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          console.log("article to delte found ", res.data);
          setUserDeleteState({
            ...userDeleteState,
            userId: res.data.userId,
          });
          console.log("===to delete " + res.data.userId);
        } else {
          console.log("some error occured");
        }
      });
  };
  //delete modal submit
  const [clickSubmitDelete, setClickSubmitDelete] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [showErrorFormDelete, setShowErrorFormDelete] = useState(false);
  const submitUserDelete = (e: any) => {
    e.preventDefault();
    console.log("Deleting new user");
    setClickSubmitDelete(true);
    axios
      .post(appHost + "/adminapi/deleteuserbyid", {
        userId: userDeleteState.userId,
      })
      .then((res: any) => {
        console.log(res.data);
        if (res.data.status.toLowerCase() === "success") {
          setUserDeleted(true);
          setShowErrorFormDelete(false);
          //added new user, should update list
          e.target.reset();
          setClickSubmitDelete(false);
        } else if (res.data.status.toLowerCase() === "error") {
          setUserDeleted(false);
          setShowErrorFormDelete(true);
          setClickSubmitDelete(false);
        }
      });
  };
  const [userDeleteState, setUserDeleteState] = useState({
    userId: 0,
  });
  const handleUserDelete = (e: any) => {
    setUserDeleteState({
      ...userDeleteState,
      [e.target.name]: e.target.value,
    });
  };
  //search feature
  const [enableSearch, setEnableSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
  });
  const searchUser = () => {
    setEnableSearch(true);
    //setSearchQuery(searchQuery.search);
  };
  const closeSearch = () => {
    setEnableSearch(false);
  };
  const handleSearch = (e: any) => {
    setEnableSearch(true);
    //add custom code to handle on change to get all employees
    setSearchQuery({
      ...searchQuery,
      [e.target.name]: e.target.value,
    });
  };
  const submitUserSearch = (e: any) => {
    e.preventDefault();
    searchUser();
  };

  React.useEffect(() => {
    document.title = "Template Manager v1.0 | Classrooms";
  }, []);
  return (
    <>
      <DashboardHeader />
      <div className="dashboard-core">
        <div className="left-content">
          <LeftMenu />
        </div>
        <div className="main-content">
          <div className="elem-light-title">
            <div className="elem-row-flex">
              <div>
                <div className="elem-breadcrumb">
                  <Link to="/">Accueil</Link> &gt; Classrooms{" "}
                </div>
                <h2>Gestion des classrooms</h2>
                {/* <IconButton aria-label="Adding user" color="primary" onClick={handleOpen}>
                        <AddCircleOutlineRoundedIcon />
                    </IconButton> */}
                <Button
                  aria-label="Adding user"
                  startIcon={<AddCircleOutlineRoundedIcon />}
                  color="primary"
                  onClick={handleOpen}
                >
                  Créer un classroom
                </Button>
              </div>
              <div className="search-form-col">
                <form onSubmit={submitUserSearch}>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
                    <InputLabel htmlFor="search-input-top">
                      Recherche
                    </InputLabel>
                    <Input
                      id="search-input-top"
                      type="text"
                      onChange={handleSearch}
                      name="search"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="User search"
                            onClick={searchUser}
                            onMouseDown={searchUser}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </form>
                {/* {searchQuery.search} */}
              </div>
            </div>
          </div>

          {/* add new user modal */}
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
          <div style={{ overflowY: "scroll" }}>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Ajout classroom
                </Typography>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                <form action="" onSubmit={submitCreate}>
                  <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                    <TextField
                      id="standard-basic"
                      label="Nom classroom"
                      name="classroomName"
                      variant="standard"
                      onChange={handleCreation}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                    <TextField
                      id="standard-basic"
                      label="Description"
                      name="classroomDescription"
                      variant="standard"
                      onChange={handleCreation}
                    />
                  </FormControl>
                  {/*<FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Adresse e-mail" name="userEmail" variant="standard" onChange={handleCreation} />
                                </FormControl>*/}
                  {/* 
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Catégorie" name="userPost" variant="standard" onChange={handleCreation} />
                                </FormControl> */}
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120, width: "95%" }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-standard-label2">
                      Choisir catégorie classe
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label2"
                      id="demo-simple-select-standard"
                      onChange={handleCreation}
                      label="Catégorie"
                      name="classroomCategory"
                    >
                      <MenuItem value={""}>
                        <em>Choisir type classroom</em>
                      </MenuItem>
                      <MenuItem value={"coding"}>Programming</MenuItem>

                      <MenuItem value={"art"}>Art</MenuItem>

                      <MenuItem value={"maths"}>Mathematics</MenuItem>

                      <MenuItem value={"electronics"}>Electronics</MenuItem>

                      <MenuItem value={"languages"}>Languages</MenuItem>

                      {/* {templates.all.map((res: any) => {
                                             
                                            return <MenuItem value={res.id}>
                                                <em>{res.fileTitle}</em>
                                            </MenuItem>
                                        })} */}
                    </Select>
                  </FormControl>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {clickSubmit ? (
                      <>
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<PersonAddIcon />}
                          variant="outlined"
                          type="submit"
                        >
                          Enregistrer
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          startIcon={<PersonAddIcon />}
                          type="submit"
                        >
                          Enregister
                        </Button>
                      </>
                    )}
                  </div>{" "}
                  <br />
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    style={{ display: userCreated ? "flex" : "none" }}
                  >
                    Le classroom a bien été enregistré
                  </Alert>
                  <Alert
                    icon={<CloseIcon fontSize="inherit" />}
                    severity="error"
                    style={{ display: showErrorForm ? "flex" : "none" }}
                  >
                    Erreur: Cet employé existe déjà: login ou email existant ou
                    bien vous n'avez pas rempli correctement le formulaire.
                  </Alert>
                  {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                </form>
              </Box>
            </Modal>
          </div>

          {/* update modal */}
          <div style={{ overflowY: "scroll" }}>
            <Modal
              open={openUpdate}
              onClose={handleCloseUpdate}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ overflowY: "scroll" }}
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Modification classroom
                </Typography>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                <form action="" onSubmit={submitUpdate}>
                  <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                    <TextField
                      id="standard-basic"
                      label="Nom classroom"
                      name="classroomName"
                      variant="standard"
                      onChange={handleUpdate}
                      value={classroomUpdateState.classroomName}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                    <TextField
                      id="standard-basic"
                      label="Description"
                      name="userLogin"
                      variant="standard"
                      onChange={handleUpdate}
                      value={classroomUpdateState.classroomDescription}
                    />
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120, width: "95%" }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-standard-label2">
                      Choisir catégorie
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label2"
                      id="demo-simple-select-standard"
                      onChange={handleUpdate}
                      label="Catégorie"
                      name="classroomCategory"
                      defaultValue={classroomUpdateState.classroomCategory}
                    >
                      <MenuItem value={""}>
                        <em>Choisir type classroom</em>
                      </MenuItem>
                      <MenuItem value={"coding"}>Programming</MenuItem>

                      <MenuItem value={"art"}>Art</MenuItem>

                      <MenuItem value={"maths"}>Mathematics</MenuItem>

                      <MenuItem value={"electronics"}>Electronics</MenuItem>

                      <MenuItem value={"languages"}>Languages</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 120, width: "95%" }}
                    fullWidth
                  >
                    <InputLabel id="publish-classroom-label44">
                      Publié
                    </InputLabel>
                    <Select
                      labelId="publish-classroom-label44"
                      id="publish-article-select"
                      onChange={handleUpdate}
                      label="Publié"
                      name="classroomPublished"
                      defaultValue={parseInt(
                        classroomUpdateState.classroomPublished,
                        10
                      )}
                    >
                      <MenuItem value={1}>Oui</MenuItem>
                      <MenuItem value={0}>Non</MenuItem>
                    </Select>
                  </FormControl>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {clickSubmitUpdate ? (
                      <>
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<PersonAddIcon />}
                          variant="outlined"
                          type="submit"
                        >
                          Enregistrer
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          startIcon={<PersonAddIcon />}
                          type="submit"
                        >
                          Enregister
                        </Button>
                      </>
                    )}
                  </div>{" "}
                  <br />
                  <input
                    type="hidden"
                    name="userId"
                    value={classroomUpdateState.userId}
                    onChange={handleUpdate}
                  />
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    style={{ display: userUpdated ? "flex" : "none" }}
                  >
                    Le classroom a bien été modifié
                  </Alert>
                  <Alert
                    icon={<CloseIcon fontSize="inherit" />}
                    severity="error"
                    style={{ display: showErrorFormUpdate ? "flex" : "none" }}
                  >
                    Une Erreur s'est produite lors de la modification du
                    classroom
                  </Alert>
                  {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                </form>
              </Box>
            </Modal>
          </div>
          {/*delete modal */}
          <div style={{ overflowY: "scroll" }}>
            <Modal
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Voulez-vous vraiment effacé cet employé?
                </Typography>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                <form action="" onSubmit={submitUserDelete}>
                  {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUpdate} />
                                </FormControl> */}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {clickSubmitDelete ? (
                      <>
                        <LoadingButton
                          loading
                          loadingPosition="start"
                          startIcon={<PersonRemoveIcon />}
                          variant="outlined"
                          type="submit"
                          color="secondary"
                        >
                          Effacer
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<PersonRemoveIcon />}
                          type="submit"
                        >
                          Effacer
                        </Button>
                      </>
                    )}
                  </div>{" "}
                  <br />
                  <input
                    type="hidden"
                    name="userId"
                    value={userDeleteState.userId}
                    onChange={handleUserDelete}
                  />
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    style={{ display: userDeleted ? "flex" : "none" }}
                  >
                    L'employé a bien été supprimé
                  </Alert>
                  <Alert
                    icon={<CloseIcon fontSize="inherit" />}
                    severity="error"
                    style={{ display: showErrorFormDelete ? "flex" : "none" }}
                  >
                    Une Erreur s'est produite lors de la suppréssion de
                    l'employé
                  </Alert>
                  {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                </form>
              </Box>
            </Modal>
          </div>
          {/* dataTable for users */}
          <ClassroomsDataTable
            searchQuery={searchQuery.search}
            enableSearch={enableSearch}
            closeSearch={closeSearch}
            userDeleted={userDeleted}
            userUpdated={userUpdated}
            newUserCreated={userCreated}
            showModalUpdate={openUpdate}
            toggleUpdate={toggleUpdateModal}
            toggleDelete={toggleDeleteModal}
          />
        </div>
      </div>
    </>
  );
};

export default ClassroomsList;
