import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "axios";
import * as Tools from "../../utils/tools";

export default function UsersDataTable(prop: any) {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "userName", headerName: "Nom", width: 130 },
    { field: "userEmail", headerName: "Email", width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 91,
    // },
    {
      field: "userLogin",
      headerName: "Nom login",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      //   valueGetter: (params: GridValueGetterParams) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      // },
    },
    {
      field: "userJob",
      headerName: "Poste",
      description: "Le nom du poste",
      sortable: false,
      width: 100,
    },
    {
      field: "userMembership",
      headerName: "Abonnement/mois",
      description: "Abonnement par mois",
      sortable: false,
      width: 100,
    },
    {
      field: "operations",
      headerName: "Operations",
      description: "This column contains buttons to update each row",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        // console.log(params);
        const editUser = (e: any) => {
          console.log("editiing " + params.id);
          prop.toggleUpdate(true, params.id);
        };
        const deleteUser = (e: any) => {
          console.log("deleting " + params.id);
          prop.toggleDelete(true, params.id);
          //prop.toggleUpdate(true, params.id);
        };
        return (
          <>
            <IconButton
              aria-label="Modification employe"
              color="primary"
              onClick={editUser}
            >
              <ModeEditSharpIcon />
            </IconButton>
            <IconButton
              arial-label="Delete employe"
              color="secondary"
              onClick={deleteUser}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const [employees, setEmployees]: [any, any] = React.useState({
    all: [],
  });

  const searching = () => {
    //if (prop.enableSearch) {
    //let callback = v => v % 2 === 0;
    let searchCallBack = (element: any) => {
      // console.log(searchQuery.userName + '===' + prop.searchQuery);
      // console.log(/prop.searchQuery/i.test(searchQuery.userName.toLowerCase()));
      // return prop.searchQuery.toLowerCase()===element.userName.toLowerCase();
      return (
        element.userName
          .toLowerCase()
          .includes(prop.searchQuery.toLowerCase()) ||
        element.userEmail.toLowerCase().includes(prop.searchQuery.toLowerCase())
      );
    };
    console.log("searching" + prop.searchQuery);
    console.log("employees found", employees.all.length);
    // const even = employees.filter(callback);
    if (prop.searchQuery.length > 0) {
      // getEmployees();
      const found = employees.all.filter(searchCallBack);

      setEmployees({
        ...employees,
        all: found,
      });

      console.log("found", found);
    } else {
      getEmployees();
    }
    //}
  };
  //get employees
  const getEmployees = () => {
    Tools.getLightAxios()
      .post(Tools.getHost() + "adminapi/users", {
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          let formatedDatas = [];
          for (let i = 0, c = res.data.users.length; i < c; i++) {
            formatedDatas.push({
              id: res.data.users[i].id,
              userName: res.data.users[i].userName,
              userEmail: res.data.users[i].userEmail,
              userLogin: res.data.users[i].userLogin, 
              userMemberShip: '',
              dateCreation: res.data.users[i].dateCreation,
            });
          }

          setEmployees({
            ...employees,
            all: formatedDatas,
          });
        }
      });
  };
  const [calledOnce, setCalledOnce] = React.useState(false);
  React.useEffect(() => {
    if (prop.enableSearch) {
      searching();
    }
    //else {
    if (prop.newUserCreated || prop.userUpdated || prop.userDeleted)
      getEmployees();
    if (!calledOnce) {
      getEmployees();
      setCalledOnce(true);
    }
    //}
  }, [
    prop.newUserCreated,
    prop.userUpdated,
    prop.userDeleted,
    prop.enableSearch,
    prop.searchQuery,
  ]);

  return (
    <div className="datatable-custom" style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={employees.all}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}
