import React, { useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import axios from "axios";
import parse from "html-react-parser";
import { HiUser } from "react-icons/hi";
import * as tools from "../../utils/tools";
import AppContext, { AppContextProvider } from "../../context/AppContext";
import Logo from "../../image/footer-logo-400x400.png";

import { FormControl, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
const baseURL: string = process.env.REACT_APP_API_URL + "";
const appHost: string = process.env.REACT_APP_APP_HOST + "";

export const FinishCmsSetup = (prop: any) => {
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState({
    password: "",
    login: "",
    email: "",
  });

  const [errorState, setErrorState] = useState({
    error: "",
  });

  const {
    setIsLogin,
    loginAdmin,
    adminAuthInfo,
    isLogin,
    checkToken,
    errorLogin,
    userInfo,
    setUserInfo,
  }: any = useContext(AppContext);

  const submitSetup = (e: any) => {
    e.preventDefault();
    showError();
    setClickSubmit(true);

    axios
      .post(tools.getHost() + "adminapi/finishsetup", {
        login: loginState.login,
        password: loginState.password,
		email: loginState.email
      })
      .then(({ data }: any) => {
        if (data.status === "success") {
          //all good
		  e.target.reset();
		  
        } else {
          //error
        }
      });
    // console.log(appHost + 'adminapi/login/');
    // hideError();
    // console.log('logged in');
    // loginAdmin(loginState.login, loginState.password);
    // if (adminAuthInfo.loggedIn) {
    // 	setClickSubmit(false);
    // }else{
    // 	setTimeout(()=>{
    // 		setClickSubmit(true);
    // 	}, 2000);
    // }
  };
  const handleLogin = (e: any) => {
	setClickSubmit(false);
    setLoginState({
      ...loginState,
      [e.target.name]: e.target.value,
    });
  };
  const [hideLoginError, setHideLoginError] = useState(false);
  const hideError = () => {
    setHideLoginError(true);
  };
  const showError = () => {
    setTimeout(() => {
      setHideLoginError(false);
    }, 400);
  };

  const [userLanguage, setUserLanguage]: [any, any] = useState({
    currentLanguage: "",
  });

  const handleLanguageForm = (e: any) => {
    setUserLanguage({
      userLanguage,
      [e.target.name]: e.target.value,
    });
  };
  const changeLanguage = () => {
    setUserInfo((prevState: any) => ({
      ...prevState,
      defaultLanguage: userLanguage.currentLanguage,
    }));
    localStorage.setItem("currentLanguage", userLanguage.currentLanguage);
  };

  const [clickSubmit, setClickSubmit] = useState(false);

  React.useEffect(() => {
    //errorState.error='';
  }, []);

  return (
    <>
      <div className="app-core">
        <div className="app-login">
          <div className="app-login-wrap">
            <form onSubmit={submitSetup} className="login-form">
              <div className="login-title">
                <div className="login-logo-wrap">
                  <h2 className="elem-text-align-center elem-width-full elem-flex-center-inline elem-font-upper">
                    {tools.translate("Etape de configuration cms")}
                  </h2>
                </div>
              </div>
			  <Alert severity="info" sx={{marginBottom: '10px'}}>Pour continuer, vous devez entrer un login et mot de passe administrateur</Alert>
			 
              <div className="login-error">
                {errorLogin ? (
                  <>
                    <div
                      className="error-login-wrap"
                      style={{ display: hideLoginError ? "none" : "flex" }}
                    >
                      {tools.translate("Wrong email or password")}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <label>
                {tools.translate("Entrez votre login")}
                <input
                  name="login"
                  type="text"
                  onChange={handleLogin}
                  onMouseEnter={hideError}
				  required
                />
              </label>

              <br />
              <label>
                {tools.translate("Entrez votre email")}
                <input
                  name="email"
                  type="email"
                  onChange={handleLogin}
                  onMouseEnter={hideError}
				  required
                />
              </label>
              <br />
              <label>
                {tools.translate("Entrez un nouveau mot de passe")}
                <input
                  name="password"
                  type="password"
                  onChange={handleLogin}
                  onMouseEnter={hideError}
				  required
                />
              </label>
              <br />

              {errorState.error == "error" ? (
                <>
                  <p className="error">
                    {tools.translate("Account not found")}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="message-sent"
                    style={{
                      display: errorState.error == "success" ? "block" : "none",
                    }}
                  >
                    {tools.translate("Welcome")}
                  </p>
                </>
              )}
              <div className="login-footer">
                {/* <button className="app-bt" >{tools.translate('Connect')}</button> */}
                {clickSubmit ? (
                  <>
                    <LoadingButton
                      loading
                      loadingPosition="start"
                      variant="outlined"
                      type="submit"
                      className="app-bt"
                      style={{ paddingLeft: "34px" }}
                    >
                      Continuer
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    <Button variant="outlined" type="submit" className="app-bt">
                      Continuer
                    </Button>
                  </>
                )}
              </div>
            </form>

            <div>
              <p className="loginMessage"></p>
            </div>
          </div>{" "}
          {/*end app login wrap*/}
          <div
            className="language-wrap text-center"
            style={{ maxWidth: "250px", margin: "0 auto" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default FinishCmsSetup;
