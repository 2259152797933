import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import Alert from "@mui/material/Alert";
import { Typography } from "@mui/material";
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from "@mui/material/IconButton";
import DashboardFileUpload from "./DashboardFileUpload";

const UploadPage = () => {
  const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } =
    useContext(AppContext);
  const [stats, setStats]: [any, any] = useState({
    totalEmployee: 0,
    totalAdmin: 1,
    totalGasTransactions: 0,
    totalArticles: 0,
    totalUsers: 0,
    totalMessages: 0,
  });
  const getTotalEmployee = () => {
    axios
      .post(Tools.getHost() + "adminapi/showstats", {
        loginToken: localStorage.getItem("authToken"),
      })
      .then((res: any) => {
        // console.log('stats data ');
        // console.log(res.data);
        if (res.data.status.toLowerCase() === "success") {
          // console.log('succeeded');
          setStats({
            ...stats,
            totalArticles: res.data.stats.totalArticles,
            totalMessages: res.data.stats.totalMessages,
            totalUsers: res.data.stats.totalUsers,
          });
        } else {
          console.log("status */*/*/*/*", res.data.status);
        }
      });
  };
  const [fileStats, setFileStats] = useState({
    all: [],
  });
  const getFiles = () => {
    Tools.getLightAxios()
      .post(Tools.getHost() + "adminapi/getfiles", {
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        setFileStats({
          ...fileStats,
          all: res.data.files,
        });
      });
  };
  const [fileUploaded, setFileUploaded] = useState(false);

  const checkFileUploaded = (response: any) => {
    setFileUploaded(response);
  };
  React.useEffect(() => {
    getTotalEmployee();
    getFiles();
    document.title = "Template Manager v1.0 | Fichiers";
  }, [fileUploaded]);
  return (
    <>
      <DashboardHeader />
      <div className="dashboard-core">
        <div className="left-content">
          <LeftMenu />
        </div>
        <div className="main-content">
          <h2 className="elem-light-title">Gestion des fichiers</h2>
          {/* <Alert severity="info">Cette application est en cours de construction par Francky. Veuillez revenir de temps en temps.</Alert>*/}
          <div>
            <DashboardFileUpload isFileUploaded={checkFileUploaded} />
          </div>
          <div>
            {!fileStats.all.length && (
              <>
                <div className="elem-flex-center">
                  <Alert severity="info">Pas de fichiers trouvés</Alert>
                </div>
              </>
            )}

            <div className="file-album">
              {fileStats.all && (
                <>
                  {fileStats.all.map((res: any) => {
                    
                    return (
                      <article className="album-article">
                        <img
                          src={
                            Tools.getHost() +
                            "upload/datas/image/" +
                            res.fileName
                          }
                          alt=""
                        />
                        <br />
                      </article>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPage;
