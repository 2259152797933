import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import AppContext from "../../context/AppContext";
import HomeIcon from "@mui/icons-material/Home";
import PositionedMenu from "./PositionedMenu";
import { Button } from "@mui/material";
import Logo from "../../../image/logo-rising.svg";
const DashboardHeader = () => {


    const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } = useContext(AppContext);

    return <>
        <header className="dashboard-header elem-flex">
            <div className="elem-row">
                <div className="dashboard-logo">
                {/*<Link to="/"><Button
                >Dashboard cms v1.0</Button></Link>*/}
                </div>
                <div className="dashboard-right-menu">
                    <PositionedMenu />
                </div>
            </div>
        </header>
    </>
}


export default DashboardHeader;