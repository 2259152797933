import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import axios from "axios";
import * as Tools from "../../utils/tools";
import IconButton from "@mui/material/IconButton";
import DashboardFileUpload from "../fileupload/DashboardFileUpload";
import UploadDialog from "../fileupload/UploadDialog";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  FormControl,
  Alert,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
const baseURL: string = process.env.REACT_APP_API_URL + "";
const appHost: string = process.env.REACT_APP_APP_HOST + "";

const AppSettings = () => {
  const [assignTo, setAssignTo]: [any, any] = useState();
  const { adminAuthInfo, setAdminAuthInfo, logoutAdmin } =
    useContext(AppContext);
  const [stats, setStats]: [any, any] = useState({
    totalEmployee: 0,
    totalAdmin: 1,
    totalGasTransactions: 0,
    totalArticles: 0,
    totalUsers: 0,
    totalMessages: 0,
  });
  const getTotalEmployee = () => {
    axios
      .post(Tools.getHost() + "adminapi/showstats", {
        loginToken: localStorage.getItem("authToken"),
      })
      .then((res: any) => {
        // console.log('stats data ');
        // console.log(res.data);
        if (res.data.status.toLowerCase() === "success") {
          // console.log('succeeded');
          setStats({
            ...stats,
            totalArticles: res.data.stats.totalArticles,
            totalMessages: res.data.stats.totalMessages,
            totalUsers: res.data.stats.totalUsers,
          });
        } else {
          console.log("status */*/*/*/*", res.data.status);
        }
      });
  };
  const [templates, setTemplates] = useState({
    all: [],
  });
  const getTemplates = () => {
    Tools.getLightAxios()
      .post(Tools.getHost() + "adminapi/getAllTemplates", {
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        setTemplates({
          ...templates,
          all: res.data.templates,
        });
      });
  };
  const [fileUploaded, setFileUploaded] = useState(false);

  const checkFileUploaded = (response: any) => {
    setFileUploaded(response);
  };

  //upload dialog creation
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const appearUploadDialog = () => {
    setShowUploadDialog(true);
  };
  const closeUploadDialog = () => {
    setShowUploadDialog(false);
  };

  //selecting file from upload dialog
  const [fileSelected, setFileSelected] = useState({ id: 0, fileName: "" });
  const selectFile = (aboutFile: any) => {
    setFileSelected(aboutFile);
  };
  const [articleCreationState, setArticleCreationState] = useState({
    title: "",
    content: "",
    category: "",
    previewImageId: 0,
    previewImageSrc: "",
    templateId: 0,
  });

  const [settingsState, setSettingsState] = useState({
    defaultTemplate: 0,
  });
  const handleCreation = (e: any) => {
    // setArticleCreationState({
    //   ...articleCreationState,
    //   [e.target.name]: e.target.value,
    // });

    setSettingsState({
      ...settingsState,
      [e.target.name]: e.target.value,
    });
  };

  const [clickSubmit, setClickSubmit] = useState(false);
  const [settingsSaved, setSettingsSaved] = useState(false);
  const submitSettings = (e: any) => {
    e.preventDefault();
    setClickSubmit(true);
    Tools.getLightAxios()
      .post(appHost + "adminapi/saveSettings", {
        templateId: settingsState.defaultTemplate,
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        console.log(res.data);
        if (res.data.status.toLowerCase() === "success") {
          e.target.reset();
          setSettingsSaved(true);
          setClickSubmit(false);
        } else if (res.data.status.toLowerCase() === "error") {
          setSettingsSaved(false);
          setClickSubmit(false);
        }
      });
  };
  const [defaultTemplate, setDefaultTemplate] = useState(0);
  const getDefaultTemplate = () => {
    Tools.getLightAxios()
      .post(appHost + "adminapi/getdefaulttemplate", {
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          //   setDefaultTemplate(res.data.template);

          setSettingsState((prevState) => ({
            ...prevState,
            defaultTemplate: res.data.template,
          }));
        }
      });
  };
  React.useEffect(() => {
    // getTotalEmployee();
    getDefaultTemplate();
    getTemplates();
    document.title = "Template Manager v1.0 | Settings";
  }, []);
  return (
    <>
      <DashboardHeader />
      <div className="dashboard-core">
        <div className="left-content">
          <LeftMenu />
        </div>
        <div className="main-content">
          <div>
            <h3 className="elem-section-title">Paramètres</h3>

            <form onSubmit={submitSettings} className="elem-light">
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: "95%" }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-standard-label2">
                  Template par défaut
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label2"
                  id="demo-simple-select-standard"
                  value={settingsState.defaultTemplate}
                  onChange={handleCreation}
                  label="templateType"
                  name="defaultTemplate"
                >
                  <MenuItem value={0}>
                    <em>Choisir template par défaut</em>
                  </MenuItem>
                  {templates.all.map((res: any) => {
                    return (
                      <MenuItem value={res.id}>
                        <em>{res.fileTitle}</em>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              
              {clickSubmit && (
                <>
                  <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<PersonAddIcon />}
                    variant="outlined"
                    type="submit"
                  >
                    Enregistrer
                  </LoadingButton>
                </>
              )}
              
              {!clickSubmit && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<PersonAddIcon />}
                    type="submit"
                  >
                    Enregister
                  </Button>
                </>
              )}
              <Alert
                icon={<CheckIcon fontSize="inherit" />}
                severity="success"
                style={{ display: settingsSaved ? "flex" : "none" }}
              >
                Vos paramètres ont été enregistré
              </Alert>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSettings;
