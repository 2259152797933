import React from "react";
import { Link } from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Button, IconButton } from "@mui/material";
import { HomeRounded } from "@mui/icons-material";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import ArticleIcon from "@mui/icons-material/Article";
import MessageIcon from "@mui/icons-material/Message";
import EditNoteIcon from "@mui/icons-material/EditNote";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AppLogo from "../../../image/logo-rising.svg"; 
import { IoMdSchool } from "react-icons/io";
import { IoMdNotifications } from "react-icons/io";
const LeftMenu = () => {
  return (
    <>
      <div className="dashboard-core-leftmenu">
        <h2 className="dashboard-name">Templates Manager v1.0</h2>
        {/* <img src={AppLogo} alt="App Logo" style={{display: 'block', 
            margin: '0 auto', width: '32px', height: '32px'}}/> */}
        <p className="copyright-rising-wrap">Product By <a href="https://risingconnectstudio.mg" target="_blank">Rising Connect Studio</a></p>
        <nav>
          <ul>
            <li>
              <Link to="/">
                <Button
                  aria-label="Dashboard"
                  startIcon={<BarChartRoundedIcon />}
                >
                  Dashboard
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/articles">
                <Button
                  aria-label="Adding new article"
                  startIcon={<EditNoteIcon />}
                >
                  Gestion articles
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/pages">
                <Button
                  aria-label="Adding new article"
                  startIcon={<EditNoteIcon />}
                >
                  Gestion Pages
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/files">
                <Button
                  aria-label="Adding new article"
                  startIcon={<InsertDriveFileIcon />}
                >
                  Gestion fichiers
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/templates">
                <Button
                  aria-label="gestion templates"
                  startIcon={<InsertDriveFileIcon />}
                >
                  Gestion templates
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/plugins">
                <Button
                  aria-label="gestion templates"
                  startIcon={<InsertDriveFileIcon />}
                >
                  Gestion plugins
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/users">
                <Button
                  aria-label="Adding user"
                  startIcon={<AccountCircleRoundedIcon />}
                >
                  Gestion utilisateurs
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/admin">
                <Button
                  aria-label="Adding user"
                  startIcon={<AccountCircleRoundedIcon />}
                >
                  Gestion admin
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/messages">
                <Button aria-label="Inbox" startIcon={<MessageIcon />}>
                  Gestion messages
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/devis">
                <Button aria-label="Inbox" startIcon={<AccountCircleRoundedIcon />}>
                  Gestion devis
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/clients">
                <Button aria-label="Inbox" startIcon={<AccountCircleRoundedIcon />}>
                  Gestion Clients
                </Button>
              </Link>
            </li> 
            <li>
              <Link to="/factures">
                <Button
                  aria-label="Adding new article"
                  startIcon={<EditNoteIcon />}
                >
                  Gestion Factures
                </Button>
              </Link>
            </li>

            <li>
              <Link to="/classrooms">
                <Button aria-label="Inbox" startIcon={<IoMdSchool />}>
                  Gestion Classrooms
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/classrooms">
                <Button aria-label="Inbox" startIcon={<IoMdNotifications />}>
                  Notifications
                </Button>
              </Link>
            </li>
            {/* <li><Link to="/gas_assignment">
                        <Button aria-label="Dashboard"
                            startIcon={<LocalGasStationIcon/>}>
                            Gestion carburant
                        </Button></Link>
                    </li> */}
            {/* <li><Link to="/consuming">
                        <Button aria-label="Historique consommation carburant"
                            startIcon={<HistoryRoundedIcon />}>
                            Historique consommation Carburant
                        </Button>
                    </Link></li> */}
            <li>
              <Link to="/settings">
                <Button
                  aria-label="Paramètres"
                  startIcon={<SettingsRoundedIcon />}
                >
                  Paramètres
                </Button>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default LeftMenu;
