import { useState } from "react";
import * as appCrypt from "./crypt.js";
import axios from "axios";
import {LightAxios} from "./LightAxios";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';
const appUpload: string = process.env.REACT_APP_APP_UPLOAD + '';

export function isOnline() {
	try {
		const check = window.localStorage.getItem("userOnline");
		if (typeof check === 'undefined')
			return false;
		// console.log('app key', process.env.REACT_APP_APP_KEY);
		// console.log('user online', check);
		let salt = process.env.REACT_APP_APP_KEY;
		salt = typeof salt === 'undefined' ? '' : salt;
		const result = (check != null && appCrypt.decryptData(check, salt))
			;
		// const result=(window.localStorage.getItem("userOnline") && appCrypt.decryptData(check, salt))?true:false;
		console.log('test result', result);
		return result;
	} catch (e) {
		console.log('not online:' + e);
		return false;
	}
};

export function getUser() {
	const aboutUser = appCrypt.decryptData(window.localStorage.getItem("aboutUser"), process.env.REACT_APP_APP_KEY);
	// console.log('my about', aboutUser);
	return aboutUser;
};

/**
 * get user information
 */
export function checkUser(userId: any) {
	axios.get(process.env.REACT_APP_API_URL + "users.php?id=" + userId)
		.then(response => {
			// console.log('debug',crypt.encryptData(response.id,process.env.APP_KEY));
			// window.localStorage.removeItem("aboutUser");
			// window.localStorage.removeItem("userOnline"); 
			// window.localStorage.setItem("userOnline", 
			// 	appCrypt.encryptData(response.id, process.env.REACT_APP_APP_KEY));
			window.localStorage.setItem("aboutUser",
				appCrypt.encryptData(response, process.env.REACT_APP_APP_KEY));
			//window.localStorage.setItem("SOME_CODE", crypt.encryptData({name: response.id},   myConst.APP_KEY));
			//navigate('/profile/');
			//window.location.reload();	
		})
}


// export const getUserData=()=>{
//         axios.post(baseURL+'getuserdata/',
//         {
//             header: localStorage.getItem("loginToken")
//         }).then((response:any)=>{
//             //console.log('user name',response.data.userName);
//             localStorage.setItem("userName", response.data.userName);
//             // setUserState({
//             // ...userState,
//             // userName: response.data.user.userName});
//         })
// }

export function sanitizeHtml(str: string) {
	let result: string = (str + ' ')
		.replaceAll('&amp;lt;', '&lt;')
		.replaceAll('&amp;gt;', '&gt;')
		.replaceAll('&lt;', '<')
		.replaceAll('&gt;', '>')
		.replaceAll('&nbsp;', ' ')
		.replaceAll('&amp;nbsp;', ' ')
		.replaceAll('&quot;', '"')


	// console.log(result.split('>'));
	let safeResult: string = '';

	//loop over all elements of the article
	result.split('>').forEach(element => {
		// console.log(element);
		//add your html element to parse here
		//testing if the element contains html tags, 
		//everything else shall not be rendered as html 
		if (element.indexOf("<div") >= 0
			|| element.indexOf("</div") >= 0
			|| element.indexOf("<br") >= 0
			|| element.indexOf("<p") >= 0
			|| element.indexOf("</p") >= 0
			|| element.indexOf("<img") >= 0
			|| element.indexOf("<a") >= 0
			|| element.indexOf("</a") >= 0
			|| element.indexOf("<h1") >= 0
			|| element.indexOf("</h1") >= 0
			|| element.indexOf("<h2") >= 0
			|| element.indexOf("</h2") >= 0
			|| element.indexOf("<h3") >= 0
			|| element.indexOf("</h3") >= 0
			|| element.indexOf("<h4") >= 0
			|| element.indexOf("</h4") >= 0
			|| element.indexOf("<h5") >= 0
			|| element.indexOf("</h5") >= 0
			|| element.indexOf("<h6") >= 0
			|| element.indexOf("</h6") >= 0
			|| element.indexOf("<strong") >= 0
			|| element.indexOf("</strong") >= 0
		) {
			element += '>';
			// console.log(element, 'is html indeed');
			//let myElem=document.createElement(element);
			//the following attribute sanitizing must me improved
			element = element.replaceAll('onClick', 'onClick_');
			element = element.replaceAll('onclick', 'onClick_');
			element = element.replaceAll('onMouseEnter', 'onMouseEnter_');
			element = element.replaceAll('onmouseEnter', 'onMouseEnter_');
			element = element.replaceAll('onmouseenter', 'onMouseEnter_');
			element = element.replaceAll('ondblclick', 'onDblclick_');
			element = element.replaceAll('href="javascript:', 'href="');
			//console.log('this is my element: ', element);
		} else {
			// console.log(element, 'is not hml');
			element += '>';
			element = element.replaceAll('<', '&lt;').replaceAll('>', '&gt;'); //should be replaced by &lt; or &gt;
		}

		//adding all the result to saferesult

		safeResult += element;

	});

	return safeResult;
}

export const getLastProfile = () => {
	axios.post(baseURL + 'getlastprofile/',
		{
			header: localStorage.getItem("loginToken")
		}).then((res: any) => {
			if (res.data.status === 'success') {
				/*setLastProfile({
					...lastProfile,
					src: res.data.file.src
				})*/
				localStorage.setItem('lastProfile', res.data.file.src);
				localStorage.setItem('userNumber', (res.data.user.number) + '');
			} else {

			}
		});
}

/**
 * 
 * translate string
 */
export const translate = (str: string) => {

	// axios.post(baseURL+'translate/', 
	// {
	// 	header: window.location, 
	// 	translate: str 
	// })
	// .then((res)=>{

	// })
	return str;
}

/**
* show sample pagination
*
*/
export const simplePagination = (articles: any) => {

}


/**simple toggle
*
*/
export const toggleState = (id: string) => {
	// const [settingsState, setSettingsState]:[any,any]=useState({
	//     id: ''
	// })
	// const changeEachSettingsState=(id:string)=>{
	//     setSettingsState((prev: any) => ({
	//         [id]: !prev[id]
	//     }))
	// }
}

export const getHost = () => {

	return process.env.REACT_APP_APP_HOST + '';
}

export const getBaseUrl = () => {
	return process.env.REACT_APP_API_URL + '';
}

export const getLightAxios=( )=>{
	return new LightAxios( );
}