import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import * as Tools from "../../../utils/tools"; 
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import {Link} from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
 

export default function ReadClassroomsDataTable(prop: any) {

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Cours', width: 130 },
    { field: 'description', headerName: 'Content', width: 130 }, 
   
    { field: 'category', headerName: 'Category', width: 100 },
    {
      field: 'operations',
      headerName: 'Operations',
      description: 'This column contains buttons to update each row',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        // console.log(params);
        const editUser = (e: any) => {
          console.log('editiing ' + params.id);
          prop.toggleUpdate(true, params.id);
        }
        const deleteUser = (e: any) => {
          console.log('deleting ' + params.id);
          prop.toggleDelete(true, params.id);
          //prop.toggleUpdate(true, params.id);
        }

        const unPublishArticle=()=>{
          prop.unPublishArticle(params.id);
        }
        return <>
           {/*<PublicIcon/>*/}
          {/*<PublicOffIcon/>*/}

          <IconButton aria-label="Ne pas publié" color="primary" onClick={unPublishArticle} ><PublicOffIcon /></IconButton>
          <IconButton aria-label="Modification classroom" color="primary" onClick={editUser} ><ModeEditSharpIcon /></IconButton>
          <IconButton arial-label="Suppression classroom" color="secondary" onClick={deleteUser}><DeleteOutlineOutlinedIcon /></IconButton>

          <Link to={"/classrooms/"+params.id}><FaBookOpen title="Ajout cours" color="primary" style={{fontSize: '12px'}} /></Link>
        </>
      }
    }
  ];
  const [employees, setEmployees]: [any, any] = React.useState({
    all: []
  })

  const searching = () => {
 
    let searchCallBack = (element: any) => {
 
      return element.classroomName.toLowerCase().includes(prop.searchQuery.toLowerCase()) ||
        
        element.classroomDescription.toLowerCase().includes(prop.searchQuery.toLowerCase())  
    }
    console.log('searching' + prop.searchQuery)
    console.log('employees found', employees.all.length);
  
    if (prop.searchQuery.length > 0) {
      // getEmployees();
      const found = employees.all.filter(searchCallBack);

      setEmployees({
        ...employees,
        all: found
      });

      console.log('found', found);
    } else {
      getEmployees();
    } 
  }
  //get employees
  const getEmployees = () => {

    Tools.getLightAxios( ).post(Tools.getHost() + "adminapi/getAllTutorialsByClassroom", {header: localStorage.getItem('authToken'), classroomId: prop.classroomId})
    .then((res: any) => {
     
      if (res.data.status.toLowerCase()==='success') {

        // const totalLength = res.data.length; 
          // console.log('my data');
          //format employees 

          // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
          let formatedDatas = [];
          // formatedDatas.push({ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 })
          for (let i = 0, c = res.data.users.length; i < c; i++) {
            // console.log(res.data[i].userName);
            formatedDatas.push({ id: res.data.users[i].id, name: res.data.users[i].chapterName, description: res.data.users[i].chapterContent,category:res.data.users[i].chapterCategory,  dateCreation: res.data.users[i].dateCreation});

          }
          //console.log('formated datas: ');
          //console.log(formatedDatas);
          setEmployees({
            ...employees,
            all: formatedDatas
          });
         
      } else {
        // console.log('error');
      }

    })
  }
  const [calledOnce, setCalledOnce] = React.useState(false);
  React.useEffect(() => {
    if (prop.enableSearch) {
      searching();
    }
    //else {
    if (prop.newUserCreated || prop.userUpdated || prop.userDeleted)
      getEmployees();
    if (!calledOnce) {
      getEmployees();
      setCalledOnce(true);
    }
    //}
  }, [prop.newUserCreated, prop.userUpdated, prop.userDeleted, prop.enableSearch, prop.searchQuery])

  return (
    <div className="datatable-custom" style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={employees.all}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection
      />
    </div>
  );
}