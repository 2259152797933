import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as appTools from "../utils/tools.js";
import axios from "axios";
import { useBarLoader } from "../../../hooks/useBarLoader/useBarLoader";
const baseURL: string = process.env.REACT_APP_API_URL + "";
const appHost: string = process.env.REACT_APP_APP_HOST + "";

/*
const AppContext=React.createContext({isOnline:  window.localStorage.getItem("userOnline")!== null?true:false});*/

const AppContext = React.createContext<any>({});

export const AppContextProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [checkToken, setCheckToken] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: 0,
    name: "",
    lastProfile: "",
    email: "",
    biography: "",
    money: 0,
    hp: 0,
    mp: 0,
    defaultLanguage: "en",
    totalClassrooms: 0,
    totalContact: 0,
    totalSkills: 0,
    totalMessages: 0,
    totalAlerts: 0,
    profilePicture: "",
    coverPicture: "",
    showChat: "",
    enableDarkMode: false,
  });
  const [errorLogin, setErrorLogin] = useState(false);
  const [adminAuthInfo, setAdminAuthInfo] = useState({
    loggedIn: false,
    adminName: "",
  });

  //employees list
  const [employees, setEmployees] = useState({
    all: [],
  });

  const loginUser = (userLogin: any, userPassword: any) => {
    axios
      .post(baseURL + "userlogin/", {
        password: userPassword,
        login: userLogin,
      })
      .then((res: any) => {
        console.log("login data");
        console.log(res.data);
        console.log(res.data[0]);
        //res.data=res.data[0];
        if (res.data[0].status.toLowerCase() === "error") {
          // setErrorState({
          // ...errorState,
          // error: 'error'
          // });
          console.log("some error");
          setErrorLogin(true);
          // alert('error');
        } else if (res.data[0].status.toLowerCase() === "success") {
          console.log("great, welcome");
          localStorage.setItem("loginToken", res.data[0].loginToken);
          localStorage.setItem("userName", res.data[0].userName);
          localStorage.setItem("userEmail", res.data[0].userEmail);
          localStorage.setItem("userType", "user");
          localStorage.setItem("userBiography", "");
          localStorage.setItem("userMoney", "0");
          localStorage.setItem("userId", res.data[0].userId);
          setIsLogin(true);
          setErrorLogin(false);
          setUserInfo((prevState) => ({
            ...prevState,
            id: res.data[0].userId,
            name: res.data[0].userName || localStorage.getItem("userName"),
          }));

          // {id: 0,
          // name: res.data.login.userName,
          // lastProfile: '',
          // email: '',
          // bio: '',
          // hp: 0,
          // mp: 0,
          // totalClassrooms: 0,
          // totalContact: 0,
          // totalSkills: 0,
          // totalMessages: 0,
          // totalAlerts: 0});
          // console.log('login token', res.data.login.token);
          // console.log('username', res.data.login.userName);
          // console.log('email: ', res.data.login.userEmail);

          // setErrorState({
          // ...errorState,
          // error: 'success'
          // });
          // prop.loginProp(true);
          // setTimeout(()=>{
          navigate("/profile/");
          //}, 800);
        }
      });
  };
  const loginAdmin = (
    userLogin: any,
    userPassword: any,
    callbackSuccess: any,
    callbackError: any
  ) => {
    axios
      .post(appHost + "adminapi/login/", {
        password: userPassword,
        login: userLogin,
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "error") {
          setAdminAuthInfo((prevState) => ({
            ...prevState,
            loggedIn: false,
          }));
        } else if (res.data.status.toLowerCase() === "success") {
          localStorage.setItem("authToken", res.data.login.authToken); //basic session
          localStorage.setItem("userId", res.data.login.userId);
          setAdminAuthInfo((prevState) => ({
            ...prevState,
            loggedIn: true,
          }));
        }
      });
  };
  const logout = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    localStorage.removeItem("userDateCreation");
    localStorage.removeItem("userEmail");
    setIsLogin(false);
  };

  const logoutAdmin = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    setAdminAuthInfo((prevState) => ({
      ...prevState,
      loggedIn: false,
    }));
  };

  const checkingToken = () => {
    axios
      .post(baseURL + "checktoken", {
        loginToken: localStorage.getItem("loginToken"),
        userEmail: localStorage.getItem("userEmail"),
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setCheckToken(true);
        }
      });
  };

  const checkUserOnline = () => {
    //appContext=useContext(AppContext);
    //console.log(appContext.isOnline);
    checkingToken();
    if (localStorage.getItem("loginToken") !== null && checkToken) {
      //checkLoginState(true);
      setIsLogin(true);
      return true;
    }
    return false;
  };

  const { setShowBarLoader, showBarLoader, showIt, hideIt } = useBarLoader();

  const [barValue, setBarValue] = useState(false);
  const showBarValue = () => {
    // showIt();
    setBarValue(true);
  };

  const hideBarValue = () => {
    // hideIt();
    setBarValue(false);
  };
  const value = {
    showBarValue,
    hideBarValue,
    barValue,
    setBarValue,
    checkToken,
    setCheckToken,
    logout,
    isLogin,
    setIsLogin,
    loginUser,
    loginAdmin,
    logoutAdmin,
    checkingToken,
    errorLogin,
    userInfo,
    setUserInfo,
    adminAuthInfo,
    setAdminAuthInfo,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
export default AppContext;
