import * as React from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "axios";
import * as Tools from "../../utils/tools";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import { Link } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";

export default function ClassroomsDataTable(prop: any) {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Nom", width: 130 },
    { field: "description", headerName: "Description", width: 130 },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 91,
    // },
    // {
    //   field: "userLogin",
    //   headerName: "Nom login",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   //   valueGetter: (params: GridValueGetterParams) =>
    //   //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    //   // },
    // },
    // {
    //   field: "userJob",
    //   headerName: "Poste",
    //   description: "Le nom du poste",
    //   sortable: false,
    //   width: 160,
    // },
    {
      field: "classroomPublished",
      headerName: "Publié",
      description: "Etat class si publié ou non",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        // console.log(params)
        return (
          <>
            {parseInt(params.value) > 0 ? (
              <span className="elem-published-item">Oui</span>
            ) : (
              <span className="elem-unpublished-item">Non</span>
            )}
          </>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      description: "This column contains buttons to update each row",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        // console.log(params);
        const editUser = (e: any) => {
          console.log("editiing " + params.id);
          prop.toggleUpdate(true, params.id);
        };
        const deleteUser = (e: any) => {
          console.log("deleting " + params.id);
          prop.toggleDelete(true, params.id);
          //prop.toggleUpdate(true, params.id);
        };

        const unPublishArticle = () => {
          prop.unPublishArticle(params.id);
        };
        return (
          <>
            {/*<PublicIcon/>*/}
            {/*<PublicOffIcon/>*/}

            <IconButton
              aria-label="Ne pas publié"
              color="primary"
              onClick={unPublishArticle}
            >
              <PublicOffIcon />
            </IconButton>
            <IconButton
              aria-label="Modification classroom"
              color="primary"
              onClick={editUser}
            >
              <ModeEditSharpIcon />
            </IconButton>
            <IconButton
              arial-label="Suppression classroom"
              color="secondary"
              onClick={deleteUser}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>

            <Link to={"/classrooms/" + params.id}>
              <GiBrain
                title="Ajout cours"
                color="primary"
                style={{ fontSize: "16px" }}
              />
            </Link>
          </>
        );
      },
    },
  ];
  const [employees, setEmployees]: [any, any] = React.useState({
    all: [],
  });

  const searching = () => {
    let searchCallBack = (element: any) => {
      return (
        element.name.toLowerCase().includes(prop.searchQuery.toLowerCase()) ||
        element.description
          .toLowerCase()
          .includes(prop.searchQuery.toLowerCase())
      );
    };
    console.log("searching" + prop.searchQuery);
    console.log("employees found", employees.all.length);
    // const even = employees.filter(callback);
    if (prop.searchQuery.length > 0) {
      // getEmployees();
      const found = employees.all.filter(searchCallBack);

      setEmployees({
        ...employees,
        all: found,
      });

      console.log("found", found);
    } else {
      getClassrooms();
    }
    //}
  };
  //get employees
  const getClassrooms = () => {
    Tools.getLightAxios()
      .post(Tools.getHost() + "adminapi/classrooms", {
        userId: localStorage.getItem("userId"),
      })
      .then((res: any) => {
        if (res.data.status.toLowerCase() === "success") {
          let formatedDatas = [];

          for (let i = 0, c = res.data.users.length; i < c; i++) {
            formatedDatas.push({
              id: res.data.users[i].id,
              name: res.data.users[i].classroomName,
              description: res.data.users[i].classroomDescription,
              userLogin: res.data.users[i].userLogin,
              classroomPublished: res.data.users[i].published,
              dateCreation: res.data.users[i].dateCreation,
            });
          }

          setEmployees({
            ...employees,
            all: formatedDatas,
          });
        }
      });
  };
  const [calledOnce, setCalledOnce] = React.useState(false);
  React.useEffect(() => {
    if (prop.enableSearch) {
      searching();
    }
    //else {
    if (prop.newUserCreated || prop.userUpdated || prop.userDeleted)
      getClassrooms();
    if (!calledOnce) {
      getClassrooms();
      setCalledOnce(true);
    }
    //}
  }, [
    prop.newUserCreated,
    prop.userUpdated,
    prop.userDeleted,
    prop.enableSearch,
    prop.searchQuery,
  ]);

  return (
    <div className="datatable-custom" style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={employees.all}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection
      />
    </div>
  );
}
