import React, {useState, useEffect} from "react";


export const useBarLoader=()=>{

    const [showBarLoader, setShowBarLoader]=useState(false);



    const showIt =()=>{
        setShowBarLoader(true);
    }
    
    const hideIt=()=>{
        setShowBarLoader(false);
    }


    return {showBarLoader, setShowBarLoader, showIt, hideIt};
}